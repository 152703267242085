@import "ScssVariables";
.pc {
    font-weight: 500;
    width: 100vw;
}
.header {
    min-height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    font-size: 16px;
}
.container {
    width: 100%;
}
.container_blue {
    background-color: $base-color;
}
.button {
    color: #000;
    border: 1px solid $base-color;
    padding: 15px 30px;
    cursor: pointer;
    font-weight: 500;
    width: fit-content;
}

.button:hover {
    color: #fff;
    background-color: $base-color;

}

.button_white {
    border: 1px solid #fff;
    color: #fff;
}


.button_white:hover {
    background-color: #fff;
    color: $base-color;
}

.logo {
    font-size: 48px;
    font-weight: 900;
    color: #fff;
}

.color_white {
    a {
        color: #000;
        font-weight: 500;
    }
}

.contactsContainer {
    color: $base-color;
    margin: 20px 0 20px 40px;
    display: flex;
    font-size: 20px;
}

.form {
    padding: 60px 0 85px 0;
    position: relative;
}

.img {
    position: absolute;
    width: 1150px;
    height: 700px;
    top: 0;
    right: -185px;
}

.h2 {
    font-size: 60px;
    font-weight: 700;
    color: #fff;
    max-width: 600px;
    margin-bottom: 10px;
}
.h2_sub {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 20px;
    max-width: 600px;
}
.h3 {
    font-size: 40px;
    font-weight: 800;
    color: $base-color;
    margin-bottom: 40px;
    margin: 0 auto 40px auto;
} 
.icon {
    width: 80px;
    height: 80px;
}
.functions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.function {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.function__text {
    font-size: 18px;
    margin-top: 20px;
    color: $dark-color;
    text-align: center;
}

.text {
    font-size: 30px;
    font-weight: 500;
    color: #322C36;
    max-width: 1230px;
    margin-left: 20px;
}

.input {
    box-shadow: 5px 5px 30px rgb(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    color: $base-color;
    font-size: 20px;
    font-weight: 500;
    height: 64px;
}
.width {
    max-width: 1520px;
    margin: 0 auto;
}
// .button {
//     height: 64px;
//     display: flex;
//     align-items: center; 
//     justify-content: center;
//     color: #fff;
//     font-size: 20px;
//     font-weight: 500;
//     cursor: pointer;
//     border-radius: 10px;
//     background-color: #003366;
// }

.presentationImg {
    width: 100%;
}
.pc {
    display: flex;
    flex-direction: column;
    justify-content: start;
    // margin-bottom: 30px;
}
.number {
    font-size: 48px;
    font-weight: bold;
    color: #fff;
    z-index: 2;
}
.number_blue {
    color: $base-color;
}
.number__container {
    position: relative;
    width: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    margin-bottom: 20px;
    img {
        position: absolute;
        left: 0;
        top: 0;
    }
}
.block_4 {
    padding: 40px 0;
}
.numbers {
    display: flex;
    justify-content: space-between;

}
.plus {
    border-radius: 5px;
    box-shadow: $box-shadow;
    width: calc(33.3% - 13px);
    max-width: 493px;
    padding: 20px 26px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        width: 80px;
        height: 80px;
    }
}
.pluses {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.plus__text {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 500;
}
.number__title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
}
.number__subTitle {
    font-size: 16px;
    font-weight: 500;
}
.number__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 440px;
}
.footer {
    background-color: $base-color;
    height: 140px;
    padding: 20px 0;
}
.footer__top2 {
    display: flex;
}
.footer__logoContainer {
    display: flex;
    align-items: center;
}
.contacts {
    display: flex;
    a {
        color: #fff;
    }
    div {
        color: #fff;
    }
}
.footer__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.footer__stick {
    width: 100%;
    height: 1px;
    background-color: #fff;
    margin-bottom: 20px;
}
.footer__bottom {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    text-align: center;
}
.block_2 {
    margin: 100px auto 60px auto;
}
.header__margin {
    margin-right: 20px;
}
.header__marginLeft {
    margin-left: 40px;
}
.header__container {
    display: flex; 
    align-items: center;
}
.fieds_width {
    width: 365px;
}
.block_5 {
    padding: 60px 0 40px 0;
}
.block_6 {
    margin: 5px auto 40px auto;
}
.block_7 {
    display: flex;
}
.block_8 {
    display: none;
}
.fields_width_margin {
    margin-right: 20px;
}
.modal__title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 15px;
}
.modal__subTitle {
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 500;
}
.modal__fiedsWidth {
    margin-bottom: 10px;
    width: 290px;
}
.functions__stick {
    width: 100%;
    height: 1px;
    background-color: $base-color;
    position: absolute;
    top: 180px;
}
.politicModal {
    width: 80vw;
}

.action1 {
    font-size: 24px;
    font-weight: 500;
    color: $dark-color;
    margin-top: 5px;
    text-align: center;
}
.plusIcon {
    font-size: 75px;
    color: $base-color;
    text-align: center;
}
.action2 {
    font-size: 20px;
    font-weight: 500;
    color: $dark-color;
    margin-bottom: 10px;
    text-align: center;
}

.timer__wrapper {
    max-width: 366px;
    width: calc(100vw / 5);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    position: relative;
    padding-bottom: 160px;
    overflow: visible;
}

.timer__number {
    font-size: 75px;
    color: $base-color;
    text-align: center;
}

.timer__text {
    color: $dark-color;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}

.timer__left {
    position: absolute;
    bottom: 55px;
    left: calc(-200px - 20vw);
    z-index: 2;
}

.timer__right {
    position: absolute;
    bottom: 55px;
    right: calc(-200px - 20vw);
    z-index: 2;
}

.timer__left_line {
    position: absolute;
    top: 40px;
    left: -200px;
}
.timer__right_line {
    position: absolute;
    top: 40px;
    right: -200px;
    transform: scale(-1, 1);
}

.timer__back {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.timer__back_right {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transform: scale(-1, 1);
}

@media (max-width: 1570px) {
    .container {
        padding: 0 20px;
    }
}
@media (max-width: 1420px) {
    .img {
        width: 900px;
        height: 550px;
    }
    .form {
        padding: 40px 0 60px 0;
    }
    .h2 {
        font-size: 50px;
    }
}

@media (max-width: 1200px) {
    .img {
        width: 700px;
        height: 450px;
    }
    .form {
        padding: 40px 0 60px 0;
    }
    .h2 {
        font-size: 40px;
    }
    .timer__right_line {
        display: none;
    }
    .timer__left_line {
        display: none;
    }
    .timer__back_right {
        display: none;
    }
    .timer__back {
        display: none;
    }
    .timer__wrapper {
        width: 396px;
        max-width: 396px;
    }
    .timer_part {
        background-color: #EE3F3F;
        border-radius: 10px;
        padding: 3px 22.5px 10px 22.5px;
        color: #fff;
            
        .timer__number {
            color: #fff;
        }
        .timer__text {
             color: #fff;
        }
    }
    .timer__left {
        left: -230px;
    }
    
    .timer__right {
        right: -230px;
    }
}

@media (max-width: 1100px) {
    .plus {
        width: calc(50% - 15px);
    }
}

@media (max-width: 1000px) {
    .functions__stick {
        display: none;
    }
    .function {
        width: 320px;
    }
    .img {
        width: 500px;
        height: 330px;
    }
    .form {
        padding: 20px 0 40px 0;
    }
    .h2 {
        font-size: 30px;
    }
    .h3 {
        font-size: 25px;
    }
    .block_2 {
        margin: 50px auto 30px auto;
    }
    .header__margin {
        margin-right: 10px;
    }
    .header__marginLeft {
        margin-left: 20px;
    }
    .container {
        padding: 0 10px;
    }
    .fieds_width {
        width: 245px;
    }
    .action1 {
        font-size: 18px;
        margin-top: 10px;
    }
    .plusIcon {
        font-size: 40px;
    }
    .action2 {
        font-size: 16px;
    }
    .timer__number {
        font-size: 40px;
    }
    .timer__text {
        font-size: 16px;
    }
    .timer__wrapper {
        max-width: 340px;
        width: 340px;
        padding-bottom: 100px;
    }
    .block_action {
        padding: 20px 0 0 0;
    }
    .timer_part {
        width: 100px;
        height: 76px;
    }
    .timer__left {
        left: -170px;
        bottom: 35px;
    }
    
    .timer__right {
        bottom: 35px;
        right: -170px;
    }
}

@media (max-width: 780px) {
    .header {
        min-height: 50px;
    }
    .logo {
        height: 30px;
        width: 80px;
    }
    .mobile {
        display: none;
    }
    .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0 0 0;
    }
    .img {
        position: relative;
        right: auto;
        top: auto;
        margin-bottom: -70px;
    }
    .h3 {
        margin: 0 auto 20px auto;
    }
    .form__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .h2 {
            text-align: center;
        }
        .h2_sub {
            text-align: center;
        }
    }
    .block_4 {
        padding: 20px 0;
    }
    .block_5 {
        padding: 30px 0 10px 0;
    }
    .block_6 {
        margin: 5px auto 20px auto;
    }
    .block_7 {
        flex-direction: column;
    }
    .fieds_width {
        margin-bottom: 10px;
        width: 95vw;
        max-width: 360px;
    }
    .block_8 {
        display: block;
    }
    .footer__top {
        flex-direction: column;
    }
    .footer {
        height: auto;
    }
    .footer__logoContainer {
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
    }
    .timer__text {
        max-width: 200px;
    }
    .timer__left {
        left: -60px;
        bottom: 20px;
    }
    
    .timer__right {
        bottom: 20px;
        right: -60px;
    }
}

@media (max-width: 550px) {
    .plus {
        width: 100%;
    }
    .numbers {
        flex-direction: column;
    }
    .number__wrapper {
        margin-bottom: 20px;
        align-items: start;
    }
    .number__wrapper:last-child {
        margin-bottom: 0;
    }
    .function {
        margin-bottom: 20px;
    }
    .function:last-child {
        margin-bottom: 0;
    }
    
    .politicModal {
        width: 95vw;
    }
}
@media (max-width: 480px) {
    .timer__text {
        max-width: 100%;
        width: 100%;
        text-align: center;
    }
    .timer__left {
        left: 0px;
        bottom: 90px;
        width: 100%;
    }
    .timer__right {
        left: 0;
        bottom: 10px;
        right: auto;
        width: 100%;
    }
    .timer__wrapper {
        padding-bottom: 180px;
    }
    .timer__left_line {
        display: block;
        top: 10px;
        left: 180px;
        width: 80px;
        transform: scale(-1, 1) rotateZ(300deg);
    }
    
    .timer__right_line {
        display: block;
        top: 105px;
        left: 80px;
        width: 80px;
        transform: scale(1, -1) rotateZ(300deg);
    }
}
@media (max-width: 450px) {
    .h2_sub {
        font-size: 14px;
    }
    .img {
        width: 100vw;
        height: auto;
        margin-bottom: -50px;
    }
    .number__container {
        height: 74px;
        width: 74px;
        margin-bottom: 15px;
        img {
            width: 74px;
            height: 74px;
        }
    }
    .number {
        font-size: 35px;
    }
    .number__title {
        font-size: 18px;
    }
    .plus {
        padding: 10px 7px 20px 7px;
        margin-bottom: 10px;
        width: 100%;
        img {
            width: 50px;
            height: 50px;
        }
    }
    .plus__text {
        margin-top: 10px;
        font-size: 14px;
    }
    .function__text {
        font-size: 14px;
        margin-top: 5px;
    }
    .fieds_width {
        width: 90vw;
        margin-bottom: 0;
    }
    .fields_width_margin {
        margin-right: 0;
        margin-bottom: 10px;
    }
    .footer__logoContainer {
        font-size: 12px;
    }
    .contacts  {
        font-size: 14px;
    }
    .modal__fiedsWidth {
        width: 90vw;
        max-width: 320px;
    }
    .modal__title {
        font-size: 20px;
        margin-top: 10px;
        text-align: center;
    }
    .modal__subTitle {
        text-align: center;
        font-size: 14px;
    }
}

@media (max-width: 350px) {
    .timer__wrapper {
        width: 100%;
        max-width: 100%;
    }
    .timer__left_line {
        top: 10px;
        left: 170px;
    }
    
    .timer__right_line {
        top: 105px;
        left: 70px;
    }
}
@media (max-width: 350px) {
    .contacts {
        // flex-direction: column;
        // align-self: flex-start;
        flex-wrap: wrap;
        a {
            margin-bottom: 10px;
        }
    }
}


@media (max-width: 340px) {
    .img {
        margin-bottom: -45px;
    }
}

@media (max-width: 330px) {
    .timer_part {
        padding: 3px 10px 10px 10px;
        width: 90px;
    }
    .timer__left_line {
        top: 10px;
        left: 159px;
    }
    
    .timer__right_line {
        top: 105px;
        left: 60px;
    }
}
@media (max-width: 320px) {
    .img {
        margin-bottom: -40px;
    }
}

@media (max-width: 310px) {
    .timer__left_line {
        top: 10px;
        left: 140px;
    }
    
    .timer__right_line {
        top: 105px;
        left: 50px;
    }
}
@media (max-width: 300px) {
    .timer_part {
        padding: 3px 10px 10px 10px;
        width: 80px;
    }
}
@media (max-width: 290px) {
    .timer__left_line {
        top: 10px;
        left: 130px;
    }
    
    .timer__right_line {
        top: 105px;
        left: 40px;
    }
}
.politic {
    body {
        font-family: Arial, sans-serif;
        line-height: 1.6;
        margin: 0;
        padding: 0 20px;
        background-color: #f7f7f7;
    }
    header {
        background-color: #333;
        color: #fff;
        text-align: center;
        padding: 10px 0;
        margin-bottom: 20px;
    }
    section {
        background: #fff;
        padding: 20px;
        border-radius: 5px;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
    }
    h1, h2 {
    }
    a {
        color: #0066cc;
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
}