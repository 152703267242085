// $transparent-color: #EAE4EE;
// $base-color: #C5A2E0;
// $click-color: #C09DDB;
// $second-color: #D9BAF0;
// $dark-color: #322C36;
// $grey-color: #CACACA;
// $green-color: #6CCD6A;
// $orange-color: #FFA96A;
// $red-color: #FF6C6C;
// $success-color: #99D3A0;
// $failed-color: #E39292;
// $background-color: #fff;
// $box-shadow: 5px 5px 30px rgb(0, 0, 0, 0.15);

$transparent-color: #CEE7FF;
$base-color: #003366;
$primary-color: #003366; // Main brand color
$click-color: #003163;
$second-color: #D9BAF0;
$dark-color: #322C36;
$grey-color: #CACACA;
$green-color: #03A500;
$light-green-color: #6CFF69;
$orange-color: #E38500;
$light-orange-color: #F6A430;
$red-color: #EE3F3F;
$success-color: #99D3A0;
$failed-color: #E39292;
$background-color: #fff;
$box-shadow: 5px 5px 30px rgb(0, 0, 0, 0.15);