@import "ScssVariables";

.container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container_backgroundColor {
    background-color: rgba($color: $grey-color, $alpha: .1);
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid $base-color;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 