@import "ScssVariables";

.modalWrapper {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    z-index: 7;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.2s ease-in-out;
    backdrop-filter: blur(1px);

    &.closing {
        animation: fadeOut 0.2s ease-in-out forwards;

        .modal {
            animation: slideOut 0.2s ease-in-out forwards;
        }
    }
}

.modalClose {
    display: none;
}

.modal {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
    max-height: 85vh;
    animation: slideIn 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transform-origin: center;
    width: auto;
    min-width: 200px;
}

.scroll {
    overflow-y: scroll;
    width: 100%;
    padding: 10px 20px 20px 20px;
    scrollbar-width: thin;
    scrollbar-color: $dark-color transparent;

    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-thumb {
        width: 3px;
        background-color: $dark-color;
        border-radius: 3px;
    }
}

.header {
    left: 0;
    top: 0;
    position: sticky;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    min-height: 25px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #E7E7E7;
    overflow: hidden;
}

.header__item {
    width: 25px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        filter: brightness(1.1);
        transform: scale(1.1);
    }

    &:active {
        transform: scale(0.95);
    }
}

.copy {
    background-color: $green-color;
}

.back {
    background-color: $orange-color;
}

.close {
    background-color: $red-color;
    border-top-right-radius: 10px;
    font-size: 1.25rem;
    font-weight: bold;
    align-self: flex-end;
    cursor: pointer;
}

.loader {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    animation: fadeIn 0.2s ease-in-out;
}

@media (max-width: 750px) {
    .scroll {
        padding: 10px;
    }

    .modal {
        // width: 95vw;
        margin: 10px;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: scale(0.95) translateY(20px);
    }
    to {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}

@keyframes slideOut {
    from {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
    to {
        opacity: 0;
        transform: scale(0.95) translateY(20px);
    }
}