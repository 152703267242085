@import "ScssVariables";

.container {
    font-weight: 500;
    position: relative;
}

.input {
    height: 45px;
    width: 100%;
    box-shadow: $box-shadow;
    padding: 0 10px;
    color: $dark-color;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
    border: 1px solid transparent;

    &:hover {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }

    &:focus {
        outline: none;
        border-color: $base-color;
        box-shadow: 0 0 0 3px rgba($base-color, 0.1);
    }
}

.inputValue {
    .placeholder {
        color: $grey-color;
        transform: translateY(0);
        transition: all 0.2s ease-in-out;
    }
    .input {
        padding-top: 12px;
    }
}

.inputFocus {
    .placeholder {
        color: $base-color;
        transform: translateY(0);
        animation: focusPlaceholder 0.2s ease-in-out;
    }
}

.input::placeholder {
    color: $grey-color;
    transition: opacity 0.2s ease-in-out;
}

.input:focus::placeholder {
    opacity: 0.7;
}

.placeholder {
    position: absolute;
    top: 5.5px;
    font-size: 0.82rem;
    left: 10px;
    transition: all 0.2s ease-in-out;
    transform: translateY(8px);
    pointer-events: none;
}

@media (max-width: 1600px) {
    .input {
        height: 40px;
        font-size: 0.85rem;
        border-radius: 7px;
    }
    .inputValue {
        .input {
            padding-top: 11px;
        }
        .placeholder {
            top: 5px;
        }
    }
    .inputFocus {
        .placeholder {
            top: 5px;
        }
    }
    .placeholder {
        font-size: 0.65rem;
    }
}

@keyframes focusPlaceholder {
    0% {
        transform: translateY(8px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes shake {
    0%, 100% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-5px);
    }
    75% {
        transform: translateX(5px);
    }
}

.error {
    animation: shake 0.4s ease-in-out;
    border-color: $red-color !important;
    
    &:focus {
        box-shadow: 0 0 0 3px rgba($red-color, 0.1) !important;
    }
    
    & + .placeholder {
        color: $red-color !important;
    }
}