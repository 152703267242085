.container{
    position: fixed;
    right: 20px;
    z-index: 12;
    bottom: 20px;
}

.alertWindow {
    max-width: 470px;
    width: 100vw;
    border-bottom-right-radius: 10px;
    // border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
    right: 0;
    bottom: 0;
    text-align: center;
    // animation: 1.8s 1.1s hide ease;
    z-index: 1111;
    position: relative;
    font-weight: 500;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    animation: slideInRight 0.3s ease-out, scaleIn 0.3s ease-out;
    transform-origin: center;
    transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;

    &:hover {
        transform: translateY(-1px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    }

    &.closing {
        animation: slideOutRight 0.3s ease-in forwards;
    }
}

.close {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    opacity: 0.7;
    transition: all 0.2s ease;
    padding: 4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.05);
        transform: scale(1.1);
    }

    &:active {
        transform: scale(0.95);
    }

    svg {
        width: 16px;
        height: 16px;
        transition: transform 0.2s ease;
    }
}

.ok {
    background-color: #E5F6E5;
    color: #2E7D32;
    .close svg {
        stroke: #2E7D32;
    }
}

.error {
    background-color: #FDECEA;
    color: #D32F2F;
    .close svg {
        stroke: #D32F2F;
    }
}

.warning {
    background-color: #FFF3E0;
    color: #ED6C02;
    .close svg {
        stroke: #ED6C02;
    }
}

.alertWindow + .alertWindow {
    // animation: 40s 40s hide ease;
}

@keyframes hide {
    from { opacity: 1; }
    to { opacity: 0; }
}

@media(max-width: 768px){
    .alertWindow {
        max-width: 370px;
        padding: 10px 15px;
    }
    .container {
        bottom: 70px;
    }
}
@media(max-width: 420px){
    .alertWindow {
        max-width: 300px;
        font-size: 0.75rem;
    }
}

.decreasingLine {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    animation-name: decreaseLine;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

.ok .decreasingLine {
    background-color: #4CAF50;
}

.error .decreasingLine {
    background-color: #D32F2F;
}

.warning .decreasingLine {
    background-color: #ED6C02;
}

@keyframes decreaseLine {
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
}

@keyframes slideInRight {
    from {
        opacity: 0;
        transform: translateX(30px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideOutRight {
    from {
        opacity: 1;
        transform: translateX(0);
    }
    to {
        opacity: 0;
        transform: translateX(30px);
    }
}

@keyframes scaleIn {
    from {
        transform: scale(0.95);
    }
    to {
        transform: scale(1);
    }
}