@import "ScssVariables";

.button {
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: solid 1px $base-color;
    background-color: $background-color;
    color: $dark-color;
    font-size: 0.85rem;
    font-weight: 500;
    line-height: 14px;
    height: 45px;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease-in-out;
    transform: translateY(0);

    &:active:not(.disabled) {
        transform: translateY(2px);
    }

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
        animation: none;
    }
}

.selectedClassForChangeBackColor {
    background-color: $base-color;
    color: #fff;
}

@media (max-width: 1600px) {
    .button {
        padding: 0 15px;
        height: 40px;
    }
}

.loader {
    left: 0;
    top: 0;
    z-index: 2;
    animation: fadeIn 0.2s ease-in-out;
}

.button_hover:not(.disabled):hover {
    background-color: $base-color;
    color: $background-color;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-1px);
}

.button_loading {
    cursor: wait;
    position: relative;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 200%;
        height: 100%;
        background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.2),
            transparent
        );
        animation: loading 1.5s infinite;
    }
}

@keyframes loading {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(100%);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}